
































import Vue from 'vue'

import {
  Message,
} from '@/shared/types';
import {
  onNewMessage
} from '@/client/firebase/orm'; // TODO make shared


interface Data {
    messages: Message[]

    unsubscribeHandler?: () => any
}

export default Vue.extend({
    name: 'chatViewerComponent',
    props: [
        'event',
    ],
    data: (): Data => ({
        messages: [],

        unsubscribeHandler: undefined,
    }),
    methods: {
        async initChat() {
            if (this.$props.event.id) {
                console.log('Setup handler for messages')

                this.unsubscribeHandler = await onNewMessage(this.$props.event, (message) => {

                        // scroll status
                        const MARGIN = 5;
                        const messagesElement: HTMLElement = this.$refs.messages as HTMLElement
                        const isScrollingToLatest = (messagesElement.scrollTop + messagesElement.clientHeight + MARGIN) >= messagesElement.scrollHeight

                        // add new messages
                        this.messages.push(message)

                        // scrolling
                        Vue.nextTick(() => {
                            if(isScrollingToLatest) {
                                messagesElement.scrollTop = messagesElement.scrollHeight - messagesElement.clientHeight
                            }
                        })
                    })

            } else {
                console.warn('No event id set!')
            }
        },
    },
    mounted() {
        this.initChat()
    },
    destroyed(): void {
        try {
            if (this.unsubscribeHandler) {
                this.unsubscribeHandler()
            }
        } catch (e) {
            console.warn('Could not unsubscribe to messages')
        }

    },
})

