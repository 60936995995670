











































import Vue from 'vue'
import dayjs from 'dayjs'

import { Routes } from '@/admin/config'
import LiveEventTableComponent from '@/admin/components/LiveEventTableComponent.vue'
import { LiveEvent } from '@/shared/types';
import { getEvents } from '@/admin/firebase/orm';

interface Data {
    upcomingEvents: LiveEvent[]
    pastEvents: LiveEvent[]

    loading: boolean

    ROUTES: typeof Routes
}

export default Vue.extend({
  name: 'adminEvents',
  components: {
    LiveEventTableComponent
  },
  data: (): Data => ({
        upcomingEvents: [],
        pastEvents: [],

        loading: true,

        ROUTES: Routes
    }),
    async created() {
        const events = await getEvents()

        this.upcomingEvents = []
        this.pastEvents = []

        const today = dayjs().startOf('day')

        events.forEach((event: LiveEvent) => {

          if(dayjs(event.start_at).isBefore(today)) {
              this.pastEvents.push(event)
          } else {
              this.upcomingEvents.push(event)
          }

        })

        this.pastEvents.sort((a, b) => {
            return dayjs(a.start_at).isAfter(b.start_at) ? -1 : 1
        })
        this.upcomingEvents.sort((a, b) => {
            return dayjs(a.start_at).isBefore(b.start_at) ? -1 : 1
        })


        this.loading = false
    }
    ,
})

