

















































































































import Vue from 'vue'
import dayjs from 'dayjs'

import { Routes } from '@/admin/config'
import { createEvent } from '@/admin/firebase/orm';
import { LiveEvent } from '@/shared/types';


interface Data {
    saving: boolean
    valid: boolean
    name: string

    startAtDate: any
    startAtDateMenu: boolean
    startAtTime: any
    startAtTimeMenu: boolean
}

export default Vue.extend({
    name: 'adminEventCreate',
    data: (): Data => ({
        saving: false,

        valid: true,

        name: '',

        startAtDate: null,
        startAtDateMenu: false,
        startAtTime: null,
        startAtTimeMenu: false,
    }),
    computed: {
        startAtDateFormatted() {
            const date = dayjs(this.startAtDate as any, 'YYY-MM-DD')

            return date.isValid() ? date.format('DD/MM/YYYY') : null
        },
    },
    methods: {
        async submit() {
            const isValid = (this.$refs.form as any).validate()

            if (isValid) {
                this.saving = true

                console.log('submit data')

                let event: LiveEvent = {
                    name: this.name,
                    start_at: dayjs(`${this.startAtDate} ${this.startAtTime}`, 'YYYY-MM-DD HH:mm').toDate(),
                }

                await createEvent(event)

                this.saving = false

                this.$router.push({name: Routes.Events})
            }

        },
    },
})

