




















































import Vue from 'vue'
import { Routes } from '@/admin/config';
import { LiveEvent } from '@/shared/types';
import { deleteEvent } from '@/admin/firebase/orm';
import { BASE_URL } from '@/shared/config';

interface Data {
  ROUTES: typeof Routes
  BASE_URL: string

  deletingEvent: string | undefined
}

export default Vue.extend({
  name: 'liveEventTableComponent',
  props: [
    'title',
    'events',
  ],
  data: (): Data => ({
    ROUTES: Routes,
    BASE_URL,
    deletingEvent: undefined
  }),
  methods: {
    async deleteEvent(event: LiveEvent) {
      console.log(`Deleting event '${event.name}' - ${event.id}`)

      if (await this.$confirm(
          `Delete event '${event.name}'?`,
          {
            title: 'Delete',
            color: 'error',
            buttonTrueText: 'Delete',
            buttonFalseText: 'Cancel',
            buttonTrueColor: 'error'
          })) {

        this.deletingEvent = event.id

        try {
          await deleteEvent(event)
        } catch (e) {
          console.error('Failed removing event')
        }

        this.deletingEvent = undefined

        this.$emit("updateEvents", this.$props.events.filter(e => e !== event))
        console.log(`Deleted event '${event.name}' - ${event.id}`)

      } else {
        console.log('Cancelled')
      }

    }
  }
})

