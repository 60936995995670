







import Vue from 'vue'
import {
  getAdminUser,
  signOut
} from '@/admin/firebase'
import { Routes } from '@/admin/config'

interface Data {
  user_email: string | null
}

export default Vue.extend({
  name: 'logout',
  data: (): Data => ({
    user_email: null
  }),
  methods: {
    async logout() {
      console.log('Logging out')

      if (await this.$confirm(
          `Uitloggen?`,
          {
            title: 'Uitloggen',
            color: 'error',
            buttonTrueText: 'Uitloggen',
            buttonFalseText: 'Annuleren',
            buttonTrueColor: 'error'
          })) {

        await signOut()

        await this.$router.push({name: Routes.Login})
      }
    },
  },
  async created() {
    const user = await getAdminUser()

    this.user_email = user.email
  }
})

