import firebase from 'firebase'
import 'firebase/auth'
import 'firebase/firestore'

import {
    LiveEvent,
    LiveEventFirebase,
    Message,
    MessageFirebase
} from '@/shared/types';
import {
    COLLECTION_EVENTS,
    COLLECTION_EVENTS_MESSAGES
} from '@/shared/config';
import { getApp } from '@/admin/firebase/index';
import QuerySnapshot = firebase.firestore.QuerySnapshot;
import DocumentData = firebase.firestore.DocumentData;
import Unsubscribe = firebase.Unsubscribe;
import { firebaseFunction } from '@/admin/firebase/functions';



export async function getEvents(): Promise<LiveEvent[]> {

    await getApp()

    const liveEventDocs = await firebase.firestore()
        .collection(COLLECTION_EVENTS)
        .get()

    const events: LiveEvent[] = []

    liveEventDocs.forEach((liveEventDoc) => {

        const liveEventData = liveEventDoc.data() as LiveEventFirebase

        events.push({
            ...liveEventData,
            id: liveEventDoc.id,
            start_at: liveEventData.start_at.toDate()
        })

    })

    return events
}

export async function createEvent(event: LiveEvent) {

    const e: LiveEventFirebase = {
        ...event,
        start_at: firebase.firestore.Timestamp.fromDate(event.start_at)
    }

    await firebase.firestore()
        .collection(COLLECTION_EVENTS)
        .add(e)
}

export async function deleteEvent(event: LiveEvent): Promise<void> {

    const deleteEvent = await firebaseFunction('orm-deleteEvent')
    await deleteEvent({
        id: event.id
    })
}


export async function updateEvent(event: LiveEvent) {

    const e: LiveEventFirebase = {
        ...event,
        start_at: firebase.firestore.Timestamp.fromDate(event.start_at)
    }

    await firebase.firestore()
        .collection(COLLECTION_EVENTS)
        .doc(e.id)
        .set(e)
}

export async function getEvent(id: string): Promise<LiveEvent> {

    const liveEventDoc = await firebase.firestore()
        .collection(COLLECTION_EVENTS)
        .doc(id)
        .get()

    const liveEventData = liveEventDoc.data() as LiveEventFirebase

    const liveEvent: LiveEvent = {
        ...liveEventData,
        id: liveEventDoc.id,
        start_at: liveEventData.start_at.toDate()
    }

    return liveEvent
}

// MESSAGES

export async function createMessage(message: Message, event: LiveEvent) {

    const m: MessageFirebase = {
        ...message,
        created_at: firebase.firestore.Timestamp.fromDate(message.created_at)
    }

    await firebase.firestore()
        .collection(COLLECTION_EVENTS).doc(event.id)
        .collection(COLLECTION_EVENTS_MESSAGES)
        .add(m)
}

export async function onMessages(event: LiveEvent, callback: (snapshot: QuerySnapshot<DocumentData>) => void): Promise<Unsubscribe> {

    return firebase.firestore()
        .collection(COLLECTION_EVENTS).doc(event.id)
        .collection(COLLECTION_EVENTS_MESSAGES)
        .orderBy('created_at', 'asc')
        .onSnapshot(callback)

}