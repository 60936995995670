import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import { getAdminUser } from '@/admin/firebase'

import { Routes } from '@/admin/config'

import LoginPage from '@/admin/views/LoginPage.vue'
import AdminEventsPage from '@/admin/views/AdminEventsPage.vue'
import AdminEventEditPage from '@/admin/views/AdminEventEditPage.vue'
import AdminEventCreatePage from '@/admin/views/AdminEventCreatePage.vue'
import AdminLivestreamPage from '@/admin/views/AdminLivestreamPage.vue';
import AdminEventViewPage from '@/admin/views/AdminEventViewPage.vue';

Vue.use(VueRouter)



const routes: Array<RouteConfig> = [
    {
        path: '/login',
        name: Routes.Login,
        component: LoginPage,
    },
    {
        path: '/events',
        name: Routes.Events,
        component: AdminEventsPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/event/view/:id',
        name: Routes.EventView,
        component: AdminEventViewPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/event/edit/:id',
        name: Routes.EventEdit,
        component: AdminEventEditPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/event/create',
        name: Routes.EventCreate,
        component: AdminEventCreatePage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/livestream',
        name: Routes.Livestream,
        component: AdminLivestreamPage,
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '*',
        component: AdminEventsPage,
        meta: {
            requiresAuth: true
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth) {

    try {
      await getAdminUser()
      next()
    } catch (e) {
      console.log('false, login please')
      next({name: Routes.Login})
    }

  } else {
    next()
  }
})

export default router
