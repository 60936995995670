

















































import Vue from 'vue'

import {
  startStream,
  stopStream,
  getStreamState,
  getStreamStats
} from '@/admin/wowza'


interface Data {
  openVideo: boolean

  stream_status: null | 'started' | 'stopped' | 'starting' | 'stopping' | 'resetting'
  source_connected: boolean

  interval: any | null
}

export default Vue.extend({
  name: 'StreamControlsComponent',
  data: (): Data => ({
    openVideo: false,

    stream_status: null,
    source_connected: false,

    interval: null,
  }),
  props: {
    stream_id: String,
  },
  methods: {
    startStream: async function () {
      await startStream(this.stream_id)

      await this.getDetails()

      this.startTracking()
    },

    stopStream: async function () {

      if (await this.$confirm(
          `Bent u zeker dat u de livestream wilt stoppen?`,
          {
            title: 'Stop livestream',
            color: 'error',
            buttonTrueText: 'Ja',
            buttonFalseText: 'Nee',
            buttonTrueColor: 'error'
          })) {
        await stopStream(this.stream_id)

        await this.getDetails()
      }
    },

    startTracking: async function () {
      this.stopTracking()

      await this.getDetails()

      this.interval = setInterval(async () => {
        await this.getDetails()
      }, 5000)
    },

    stopTracking: function () {
      if (this.interval != null) {
        clearInterval(this.interval)
        this.interval = null
      }
    },

    getDetails: async function () {

      const stream_state: any = await getStreamState(this.stream_id)

      this.stream_status = stream_state.state

      if (this.stream_status === 'started') {
        // check the state
        const stream_stats: any = await getStreamStats(this.stream_id)

        this.source_connected = stream_stats.connected.value === 'Yes'
      }

    },
  },
  mounted: function () {
    if (this.stream_id) this.startTracking()
  },
  watch: {
    stream_id: function (stream_id) {
      if (stream_id) this.startTracking()
    },
  },
})

