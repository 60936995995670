import firebase from 'firebase'

// These imports load individual services into the firebase namespace.
import 'firebase/auth'
import 'firebase/firestore'
// import 'firebase/functions'
import {
    AdminUser,
    UserData
} from '@/admin/types'
import Unsubscribe = firebase.Unsubscribe;

function loadApp(): Promise<firebase.app.App> {

    const appPromise: Promise<firebase.app.App> = new Promise(function(resolve, reject) {

        try {

            fetch('/__/firebase/init.json').then(async response => {
                const json = await response.json()
                const app = firebase.initializeApp(json)

                if(process.env.NODE_ENV === 'development') {
                    console.log('app dev settings')
                    firebase.firestore().settings({
                        host: "localhost:5002",
                        ssl: false
                    })
                    firebase.auth().useEmulator('http://localhost:9099/');
                    firebase.functions().useEmulator('localhost', 5001);
                }

                // console.log('app created')
                // console.log(json)
                // console.log(app)
                resolve(app)
            });

        } catch (e) {
            // console.log(e)
            reject()
        }

    })

    return appPromise
}

const appPromise: Promise<firebase.app.App> = loadApp()

export async function getApp(): Promise<firebase.app.App> {
    return appPromise
}

export async function getAdminUser(): Promise<AdminUser> {

    await getApp();

    return new Promise((resolve, reject) => {

        const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {

            if(user != null) {
                const userToken = await user.getIdTokenResult()
                const claims: UserData = userToken['claims'] as UserData

                if (claims?.privileges?.admin === true) {
                    unsubscribe()
                    resolve({
                        email: user.email ?? '',
                        name: user.displayName ?? '',
                    })
                } else {
                    unsubscribe()
                    reject(`User is not an admin`)
                }
            } else {
                unsubscribe()
                reject(`User does not exist`)
            }

        }, reject)
    })
}

export async function signOut() {
    await firebase.auth().signOut()
}

export async function onAdminUserChange(callback: (user: AdminUser | null) => void): Promise<Unsubscribe> {

    await getApp()

    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {

        if(user != null) {
            const userToken = await user.getIdTokenResult()
            const claims: UserData = userToken['claims'] as UserData

            if (claims?.privileges?.admin === true) {

                callback({
                    email: user.email ?? '',
                    name: user.displayName ?? '',
                })
                return

            }
        }

        callback(null)
    })

    return unsubscribe
}