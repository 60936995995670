

























import Vue from 'vue'
import LivePlayerComponent from '@/shared/components/LivePlayerComponent.vue';
import ConfiguredStreamControlsComponent from '@/admin/components/ConfiguredStreamControlsComponent.vue';


export default Vue.extend({
  name: 'adminLivestreamPage',
  components: {
    ConfiguredStreamControlsComponent,
    LivePlayerComponent,
  },
})

