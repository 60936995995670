























import Vue from 'vue'
import LogoutComponent from '@/admin/components/LogoutComponent.vue'
import NavBarButtonComponent from '@/admin/components/NavBarButtonComponent.vue';
import { Routes } from '@/admin/config';
import {
  getAdminUser,
  onAdminUserChange
} from '@/admin/firebase';

interface Data {
  loggedIn: boolean

  ROUTES: typeof Routes
}

export default Vue.extend({
  name: 'navBar',
  components: {
    NavBarButtonComponent,
    LogoutComponent
  },
  data: (): Data => ({
    loggedIn: false,

    ROUTES: Routes
  }),
  async created() {

    await onAdminUserChange(user => {
      this.loggedIn = user !== null
    })

  },
})

