import { firebaseFunction } from '@/admin/firebase/functions';

export const livestreams = async (data: {
    endpoint: string,
    stream_id: string,
    method?: string
}) => {

    const livestreams = await firebaseFunction('wowza_proxy-livestreams')

    const body = await livestreams(data)

    console.log('data', body)

    // TODO add typing
    // TODO add error handling
    return body.data as any

    // @ts-ignore
    // return new Promise((resolve, reject) => {resolve({live_stream: 456})})
} //firebase.functions().httpsCallable('wowza_proxy-wowzaCall');