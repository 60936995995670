export enum Routes {
    Login = 'login',

    Events = 'events',
    EventView = 'event-view',
    EventEdit = 'event-edit',
    EventCreate = 'event-create',

    Livestream = 'livestream'
}

export const REGION = 'europe-west1'

export const STREAM_ID = 'jv42jkhb'
