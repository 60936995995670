
































import Vue from 'vue'

import { LiveEvent } from '@/shared/types'
import {
  getEvent,
} from '@/admin/firebase/orm';
import ChatViewerComponent from '@/admin/components/ChatViewerComponent.vue';


interface Data {
    loading: boolean

    event?: LiveEvent
}

export default Vue.extend({
    name: 'adminEventCreate',
    components: {
      ChatViewerComponent
    },
    data: (): Data => ({
        loading: true,
        event: undefined,
    }),
    methods: {
        async loadEvent(id) {

            try {
                this.event = await getEvent(id)
            } catch (e) {
                // TODO error message
                console.error(`Event not found: '${id}'`)
            }

            this.loading = false
        },
    },
    beforeRouteEnter(to, from, next) {
        next((vm: any) => {
            vm.loadEvent(to.params.id)
        })
    },
    beforeRouteUpdate(to, from, next) {
        next((vm: any) => {
            vm.loadEvent(to.params.id)
        })
    },
})

