







import Vue from 'vue'
import { Route } from 'vue-router/types/router';

export default Vue.extend({
    name: 'navBarButtonComponent',
    props: [
      'route',
    ],
    computed: {
      currentRouteName(){
        return (this.$route as Route).name;
      },
    },
})

