





import Vue from 'vue'
import { STREAM_ID } from '@/admin/config';
import StreamControlsComponent from '@/admin/components/StreamControlsComponent.vue';


interface Data {
  stream_id: string
}

export default Vue.extend({
  name: 'ConfiguredStreamControlsComponent',
  components: {
    StreamControlsComponent
  },
  data: (): Data => ({
    stream_id: STREAM_ID
  }),
})

