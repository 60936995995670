import { getApp } from '@/admin/firebase';
import { REGION } from '@/admin/config';


export async function firebaseFunction(name: string) {
    const firebase = await getApp()

    // TODO eventually should remove the dev check!
    // with the REGION set in dev, it will call external functions
    return process.env.NODE_ENV !== 'development' ?
        firebase.functions(REGION).httpsCallable(name) :
        firebase.functions().httpsCallable(name)
}