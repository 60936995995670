import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VuetifyConfirm from 'vuetify-confirm'

const vuetify = new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                // primary: '#c7d330',
                primary: '#484693',
                // secondary: '#6f9118',
                // accent: '#82B1FF',
                // error: '#FF5252',
                // info: '#2196F3',
                // success: '#4CAF50',
                warning: '#f68956',
            },
        },
    },
})

Vue.use(Vuetify);
Vue.use(VuetifyConfirm, { vuetify })

export default vuetify
