
























































































































import Vue from 'vue'

import dayjs from 'dayjs'
import { LiveEvent } from '@/shared/types'
import {
  getEvent,
  updateEvent
} from '@/admin/firebase/orm';
import { Routes } from '@/admin/config';

interface Data {
    id: string
    loading: boolean
    saving: boolean
    valid: boolean

    name: string

    startAtDate: any
    startAtDateMenu: boolean
    startAtTime: any
    startAtTimeMenu: boolean
}

export default Vue.extend({
    name: 'adminEventCreate',
    data: (): Data => ({
        id: '',
        loading: true,
        saving: false,
        valid: true,

        name: '',

        startAtDate: null,
        startAtDateMenu: false,
        startAtTime: null,
        startAtTimeMenu: false,
    }),
    computed: {
        startAtDateFormatted() {
            const date = dayjs(this.startAtDate as any, 'YYY-MM-DD')

            return date.isValid() ? date.format('DD/MM/YYYY') : null
        },
    },
    methods: {
        async loadEvent(id) {

            try {
                this.id = id

                const event = await getEvent(id)

                this.name = event.name

                this.startAtDate = dayjs(event.start_at).format('YYYY-MM-DD')
                this.startAtTime = dayjs(event.start_at).format('HH:mm')

            } catch (e) {
                // TODO error message
                console.error(`Event not found: '${id}'`)
            }

            this.loading = false
        },
        async submit() {
            const isValid = (this.$refs.form as any).validate()

            if (isValid) {
                this.saving = true

                console.log('Updating event')

                let event: LiveEvent = {
                    id: this.id,
                    name: this.name,

                    start_at: dayjs(`${this.startAtDate} ${this.startAtTime}`, 'YYYY-MM-DD HH:mm').toDate(),
                }

                await updateEvent(event)

                this.saving = false

                await this.$router.push({name: Routes.Events})
            }

        },
    },
    beforeRouteEnter(to, from, next) {
        next((vm: any) => {
            vm.loadEvent(to.params.id)
        })
    },
    beforeRouteUpdate(to, from, next) {
        next((vm: any) => {
            vm.loadEvent(to.params.id)
        })
    },
})

