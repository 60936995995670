import { livestreams } from '@/admin/firebase/wowzaProxy';

export async function getStreamState(stream_id: string): Promise<any> {

    return await livestreams({
        endpoint: `state`,
        stream_id
    })
}

export async function getStreamStats(stream_id: string): Promise<any> {

    return await livestreams({
        endpoint: `stats`,
        stream_id
    })
}

export async function startStream(stream_id: string): Promise<any> {

    return await livestreams({
        endpoint: `start`,
        method: 'PUT',
        stream_id
    })
}

export async function stopStream(stream_id: string): Promise<any> {

    return await livestreams({
        endpoint: `stop`,
        method: 'PUT',
        stream_id
    })
}